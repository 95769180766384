<template>
  <div class="app-container app-theme-white body-tabs-shadow">
    <div class="app-container bg-plum-plate">
      <div
        style="min-height: 100vh; height: 100% !important"
        :style="{
          background: 'url(/originals/writing.webp) 0% 50% no-repeat',
        }"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="
              d-flex
              h-100
              py-2
              app-login-box
              col-md-8
              justify-content-center
              align-items-center
              flex-column
            "
          >
            <div class="app-logo-inverse" :class="logoClass"></div>
            <div class="w-100">
              <div class="modal-content">
                <div class="modal-body flex-column">
                  <div class="h5 modal-title text-center">
                    <h4 class="mt-2">
                      <div>Mis Recibos Digitales</div>
                      <span>Registrarse sólo le tomará unos segundos.</span>
                    </h4>
                  </div>
                  <form @submit.prevent="login">
                    <div class="form-row">
                      <float-label
                        label="Nombre:"
                        clase="col-lg-6 col-12"
                        id="user"
                        placeholder="Nombre"
                        type="text"
                        classInput="form-control"
                        v-model="user.nombre"
                        :required="enviado && !$v.user.nombre.required"
                      >
                      </float-label>
                      <float-label
                        label="Apellido:"
                        clase="col-lg-6 col-12"
                        id="apellido"
                        placeholder="Apellido"
                        type="text"
                        classInput="form-control"
                        v-model="user.apellido"
                        :required="enviado && !$v.user.apellido.required"
                      >
                      </float-label>
                      <float-label
                        label="CUIL:"
                        clase="col-lg-6 col-12"
                        id="cuil"
                        placeholder="CUIL"
                        type="text"
                        inputmode="numeric"
                        classInput="form-control"
                        v-model="user.cuil"
                        :required="enviado && !$v.user.cuil.required"
                        :numeric="enviado && !$v.user.cuil.numeric"
                      >
                      </float-label>
                      <float-label
                        label="Email:"
                        clase="col-lg-6 col-12"
                        id="email"
                        placeholder="Email"
                        type="email"
                        classInput="form-control"
                        v-model="user.email"
                        :required="enviado && !$v.user.email.required"
                      >
                      </float-label>
                      <!-- <float-label
                        label="Whatsapp:"
                        clase="col-lg-6 col-12"
                        id="whatsapp"
                        placeholder="EJ: 2954555555"
                        type="text"
                        classInput="form-control"
                        v-model="user.whatsapp"
                      >
                      </float-label> -->
                      <!-- <div class="col-lg-6 col-0"></div> -->
                      <float-label
                        label="Contraseña:"
                        clase="col-lg-6 col-12"
                        id="password"
                        placeholder="Contraseña"
                        type="password"
                        classInput="form-control"
                        v-model="user.password"
                        :required="enviado && !$v.user.password.required"
                      >
                      </float-label>
                      <float-label
                        label="Confirmar contraseña:"
                        clase="col-lg-6 col-12"
                        id="password"
                        placeholder="Confirmar contraseña"
                        type="password"
                        :classInput="'form-control ' + classConfirmPassword"
                        v-model="confirmPassword"
                      >
                        <template slot="validation">
                          <div
                            class="invalid-feedback"
                            v-if="enviado && !validatePassword"
                          >
                            Las contraseñas deben coincidir.
                          </div>
                        </template>
                      </float-label>
                      <div class="col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classMinPassword"
                          ></i>
                          Mínimo 8 caracteres</span
                        >
                      </div>
                      <div class="col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classNumPassword"
                          ></i>
                          Contiene números</span
                        >
                      </div>
                      <div class="col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classMayusPassword"
                          ></i>
                          Contiene mayúsculas</span
                        >
                      </div>
                      <div class="col-12">
                        <span
                          ><i
                            style="width: 20px"
                            :class="'text-center fas ' + classSamePassword"
                          ></i>
                          Coincide la nueva contraseña</span
                        >
                      </div>
                    </div>
                  </form>
                  <hr />
                  <h6 class="mb-0">
                    ¿Ya tienes cuenta?
                    <router-link to="/login" class="text-primary"
                      >Inicia sesión</router-link
                    >
                  </h6>
                </div>
                <div class="modal-footer clearfix">
                  <div class="float-right">
                    <button class="btn btn-primary btn-lg" @click="login">
                      Registrarme
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-white opacity-8 mt-3">
              Copyright © PampaDev 2021
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "@/models/user";
import { required, numeric } from "vuelidate/lib/validators";
import Axios from "axios";
import FloatLabel from "@/components/layouts/FloatLabel";
import Swal from "sweetalert2";
import Vuex from "vuex";
export default {
  name: "Register",
  components: {
    FloatLabel,
  },
  data() {
    return {
      enviado: false,
      user: new User(null, null, null, null, null, null, "user", null),
      confirmPassword: null,
    };
  },
  validations: {
    user: {
      nombre: { required },
      apellido: { required },
      cuil: { required, numeric },
      email: { required },
      password: { required },
    },
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return " desarrollo ";
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return " ima ";
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return " isnsl ";
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else {
        return " desarrollo ";
      }
    },
    //   ...Vuex.mapState(["user", "credentials", "token"]),
    validatePassword: function () {
      if (
        this.confirmPassword != "" &&
        this.confirmPassword == this.user.password
      ) {
        return true;
      } else {
        return false;
      }
    },
    classConfirmPassword: function () {
      if (this.enviado && !this.validatePassword) {
        return "is-invalid";
      } else {
        return "";
      }
    },
    classMinPassword: function () {
      let newpass = this.user.password ? this.user.password.toString() : "";
      if (newpass != null && newpass != "") {
        if (newpass.length < 8) {
          return "text-danger fa-times";
        } else {
          return "text-success fa-check";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classNumPassword: function () {
      let newpass = this.user.password ? this.user.password.toString() : "";
      let hasNumber = /\d/;
      if (newpass != null && newpass != "") {
        if (hasNumber.test(newpass)) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classMayusPassword: function () {
      let newpass = this.user.password ? this.user.password.toString() : "";
      if (newpass != null && newpass != "") {
        if (/[A-Z]/g.test(newpass)) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    classSamePassword: function () {
      let newpass = this.user.password ? this.user.password.toString() : "";
      let confirmpass = this.confirmPassword
        ? this.confirmPassword.toString()
        : "";
      if (newpass != null && newpass != "") {
        if (newpass === confirmpass) {
          return "text-success fa-check";
        } else {
          return "text-danger fa-times";
        }
      } else {
        return "fa-asterisk";
      }
    },
    send: function () {
      let vacio = "fa-asterisk";
      let error = "text-danger fa-times";
      if (
        this.classMinPassword == vacio ||
        this.classMinPassword == error ||
        this.classNumPassword == vacio ||
        this.classNumPassword == error ||
        this.classMayusPassword == vacio ||
        this.classMayusPassword == error ||
        this.classSamePassword == vacio ||
        this.classSamePassword == error
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    let title = document.querySelector("title");
    title.innerHTML = `Registrarse - MisRecibosDigitales`;
  },
  methods: {
    login() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid || !this.send) {
        return false;
      }
      Axios.post("/register", this.user)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Bienvenido",
              text: `Te has registrado con éxito.`,
            });
            this.$store.dispatch("login", this.user).then(() => {
              this.$router.push("/");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Hubo un error mientras te registrabas, por favor intenta nuevamente.`,
          });
          console.log(err);
        });
    },
  },
};
</script>